import React from 'react'
import './input.css'


const Input = ({type, name, label, update, placeholder, value, required, readOnly = false, checked, min, max, props}) => {
  const handleChange = (e) => {
    if (type === 'checkbox') {
      // Si el tipo es 'checkbox', usar e.target.checked para obtener el estado del checkbox
      update(e.target.checked);
    } else if (type === 'file') {
      // Si el tipo es 'file', actualizar el archivo
      update(e.target.files[0]);
    } else {
      // Para otros tipos de entrada
      update(e.target.value);
    }
  };
  
  return (
    <>
      {label && (<label htmlFor={name}>{`${label}${required ? '*' : ''}`}</label>)}
      <input {...props} 
      id={name} 
      name={name} 
      type={type} 
      placeholder={placeholder} 
      value={type !== 'checkbox' ? value : undefined} // No establecer value para checkboxes
      checked={type === 'checkbox' ? checked : undefined} // Solo establecer checked para checkboxes
      required={required} 
      readOnly={readOnly}
      min={min}
      max={max} 
      onChange={type==='file' ? (e) => update(e.target.files[0]) :(e) => update(e.target.value)}/>
    </>
  )
}

export default Input