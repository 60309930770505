import React, {useEffect} from 'react'
import axios from 'axios';
import TablaOferta from '../../components/tablaoferta/TablaOferta';
import {useParams,} from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import distancia_image from '../../assets/img/TEMC_distancia.svg';

const Oferta = () => {

  const {id} = useParams();   
  const [offerData, setOfferData] = React.useState(undefined);

  useEffect(() => {
      const fetchOffer = async () => {

            const params = new URLSearchParams();
            params.append('oferta_id', id);
            const url = '/api/offer?' + params.toString();
            await axios.get(url)
            .then(res => res.data).then(data => {
                setOfferData(data);
                toast.success("Oferta cargada", {position: "top-right"});
            })
            .catch(err => {
                toast.error("No se encontró la oferta", {position: "top-center", autoClose: 8 * 1000});
            });
            toast.dismiss(toastId);
        }

        const toastId =toast.loading("Cargando Oferta", {position: "top-right"});
        fetchOffer();
  }, [id])


  return (
    <>
        <div className="screen screen-2">
        <div className="sidebar">
            <div className="sidebar__container">
                <div className="sidebar__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>

                <div className="sidebar__container--title">
                    <h2 className="h2-title">¡Esto si es ahorro!</h2>
                </div>

                <div className="sidebar__container--subtitle">
                    <p className="subtitle">Es el momento de dar el siguiente paso con nosotros y pagar sólo por lo que consumes.</p>
                </div>

                <div className="sidebar__container--info-image">
                    <img src={distancia_image} alt="Más ahorro y menos ahorro"/>
                </div>
            </div>
        </div>

        <main className="main">

        {offerData && 
        <div className="main__container" style={{display: 'flex', justifyContent: 'center'}}>
            <TablaOferta offerData={offerData}/>
        </div>   
                
        }
        </main>
    </div>
  </>
  )
}



export default Oferta