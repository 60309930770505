import React from 'react';
import { Input } from '../../../components';

import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import { toast } from 'react-toastify';
import { readFacture } from '../helpers';

const General_Data = (props) => {
    const[test , setTest] = [null, null];
    const {flow, states, setters} = props;
    const {setLoading, setSimuladorOCR} = setters;
    const {loading} = states;

    const handleInputChange = (name) => (value) => {
      if (name == "tarifa"){
        setSimuladorOCR(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }else {
        setSimuladorOCR(prevState => ({
          ...prevState,
            [name]: {
              year: new Date(value).getFullYear(),
              month: new Date(value).getMonth() + 1,
              date: new Date(value).getDate(),
              formattedDate: value,
            }
        }));
      }
    };

    const handleFormSubmit = async (e) => {
        const form_el = document.querySelector('#general_data_form');
        e.preventDefault();
    
        if (!form_el.checkValidity()) {
          form_el.reportValidity();
        }
        else{
          flow.nextStage()
        }
      }


    return (
      <>
        {loading && (
          <div className="loading">
            {/* BALL */}
            <div className="container-ball">
              <div className="loader_box">
                <div className='ball'></div>
                <div className='ball'></div>
                <div className='ball'></div>
              </div>
              <div className="loader_box">
                <div className='shadow'></div>
                <div className='shadow'></div>
                <div className='shadow'></div>
              </div>
            </div>
            {/* TEXT */}
            <span className="loading-text">PROCESANDO ...</span>
            
          </div>
         )}
        
        <div className='screen screen-2'>
          <div className="sidebar">
            {/*SIDE BAR */}
            <div className="sidebar__container">
                <div className="sidebar__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>
                <div className="sidebar__container--title">
                    <h2 className="h2-title">¡Más transparentes que la energía!</h2>
                </div>
                <div className="sidebar__container--subtitle">
                    <p className="subtitle">Entiende cada detalles de tu consumo y ahorra más que nunca.</p>
                </div>
            </div>

            <div className="sidebar__container--background"></div>
            </div>

            <main className="main">
              <div className="main__container">
                  {/* FORMULARIO */}
                  <div className="main__container--form">
                      <form className="form" id="general_data_form">
                          <div className="form__row">
                              <h2>DATOS GENERALES</h2>
                          </div>
                          <div className="form__row">
                              <p className="">Con dos datos que encuentras en tu factura de electricidad, te daremos el cálculo de ahorro que podríamos generarte. ¿Los recuerdas?</p>
                          </div>
                          {/* PRODUCTO*/}
                          <div className="form__row">
                              <Input type='text' name='product' placeholder='Producto' required={true}
                                     value= {states?.simuladorOCR?.tarifa ?? ''}
                                     update={handleInputChange('tarifa')}
                              />
                          </div>

                          {/* FEHAS*/}
                           <div className="form__row">
                              <Input type='date' name='initial_day'  placeholder='Fecha Inicial' required={true}
                                     value= {states?.simuladorOCR?.fecha_inicio?.formattedDate ?? ''}
                                     update={handleInputChange('fecha_inicio')}
                              />
                              <Input type='date' name='end_date'  placeholder='Fecha Final' required={true}
                                     value= {states?.simuladorOCR?.fecha_fin?.formattedDate ?? ''}
                                     update={handleInputChange('fecha_fin')}
                              />
                          </div>
                      </form>

                  </div>

                  <div className="main__container--buttons">
                      <a onClick={() => flow.previousStage()}  className="button back"><i className="fa-regular fa-arrow-left"></i> Volver</a>
                      <a onClick={handleFormSubmit}  className="button continue">Adelante <i className="fa-regular fa-arrow-right"></i></a>
                  </div>

              </div>
            </main>
        </div>
      </>
    ) 
  }

export default General_Data;