import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import { useState } from 'react';
import { Input } from '../../../components';
import { toast } from 'react-toastify';
import {ibanValidity} from '../helpers'


const Stage_5 = (props) => {
    const [loading, setLoading] = useState(false);
    const {flow, states, setters} = props;

    const handleFormSubmit = async (e) => {
        const form_el = document.querySelector('#contrata_ya_form');
        e.preventDefault();
    
        if (!form_el.checkValidity()) {
          form_el.reportValidity();
        }
        else{
    
          if (! ((states.factura || states.facturaUploaded) && states.dniAnverso && states.dniReverso)){
            toast.error('Debes de cargar todos los archivos solicitados', {position: "top-center", autoClose: 3000});
            return;
          }

          if (! ibanValidity(states.iban)){
            toast.error('El IBAN no es válido', {position: "top-center", autoClose: 3000});
            return;
          }

        const loadingId = toast.loading('Enviando datos...');
        setLoading(true);
        await flow.contrataYaEnd(props)
          .then(
              res => {
                toast.success("¡Éxito!", {position: "top-center", autoClose: 3000});
                setters.setContrataYaSent(true);
              }
              )
              .catch(
                err =>{
                  toast.error("Error, intentalo de nuevo", {position: "top-center", autoClose: 3000});
                  console.error(err);
                }
                )
        toast.dismiss(loadingId);
        setLoading(false);


        }
      }

  return (
    <div className="screen-3 contrata_ya" style={{minHeight:'100vh'}}>

        <main className="main">
            <div className="main__container">
                <div className="main__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>

                <div className="main__container--title">
                    <h2 className="h2-title">¡Gracias por tenerlo tan claro!</h2>
                </div>

                <div className="main__container--subtitle">
                    <p className="subtitle">Nos alegra que te unas a nuestra iniciativa con la que te vamos a ayudar a ahorrar en tu factura de electricidad. Vamos a necesitar varios datos para generar el contrato de comercialización de energía, por lo que en breve te llamaremos para completar toda la información necesaria.</p>
                </div>

                {!states.contrataYaSent && (
                <div className="main__container--form" style={{backgroundColor: "rgba(255, 244, 234, 0.6)", padding: "1.5rem  1rem", marginTop: "1rem", borderRadius: "1rem"}}>
                    <form className="form" id="contrata_ya_form">
                        <div className="form__row">
                            <Input type='text' name='iban' value={states.iban} update={setters.setIban} placeholder='IBAN *' required={true}/>
                            {/* type, name, label, update, placeholder, value, required, readOnly = false */}
                        </div>

                        <div className="form__row">
                            <p className="text smaller">* DNI Anverso.</p>
                            {!states.dniAnverso
                            ? 
                            (
                              <div className="">
                                <label htmlFor='dni-adverso' className="btn-select-file-dni">
                                  <i className="fa-regular fa-upload upload-icon-dni"></i>
                                  Cargar DNI
                                </label>
                                <Input type='file' id="dni-adverso" name='dni-adverso' value={''} update={setters.setDniAnverso}/>
                              </div>
                            )
                            : 
                            (
                              <a className='btn-select-file-nie' onClick={() => {setters.setDniAnverso(null)}} >
                                <i className="fa-regular fa-trash-can delete-icon-dni"></i>
                                Eliminar DNI
                              </a>
                            )}
                        </div>
                        
                        <div className="form__row">
                            <p className="text smaller">* DNI Reverso.</p>
                            {!states.dniReverso
                            ? 
                            (
                              <div className="">
                                <label htmlFor='dni-reverso' className="btn-select-file-dni">
                                  <i className="fa-regular fa-upload upload-icon-dni"></i>
                                  Cargar DNI
                                </label>
                                <Input type='file' id="dni-reverso" name="dni-reverso" value={''} update={setters.setDniReverso}/>
                              </div>
                            )
                            : 
                            (
                              <a className='btn-select-file-nie' onClick={() => setters.setDniReverso(null)} >
                                <i className="fa-regular fa-trash-can delete-icon-dni"></i>
                                Eliminar DNI
                              </a>
                            )}
                        </div>
                        
                        {!states.facturaUploaded && (
                            <div className="form__row">
                                <p className="text smaller">* Adjúntanos tu última factura de electricidad.</p>
                                {!states.factura
                                ? (
                                    <div className="">
                                      <label htmlFor='factura-end' className="btn-select-file-dni">
                                        <i className="fa-regular fa-upload upload-icon-dni"></i>
                                        Cargar Factura
                                      </label>
                                      <Input type='file' name='factura-end' id='factura-end' value={''} update={setters.setFactura}/>
                                    </div>
                                  )
                                :
                                  (
                                    <a className='btn-select-file-nie' onClick={() => setters.setFactura(null)} >
                                      <i className="fa-regular fa-trash-can delete-icon-dni"></i>
                                        Recargar factura
                                    </a>
                                  )
                                }

                            </div>
                            )
                        }

                        <div className="form__row">
                            {!loading && (
                            <a onClick={handleFormSubmit} id="submit" className="button continue" disabled>Adelante <i className="fa-regular fa-arrow-right"></i></a>
                            )}
                        </div>
                    </form>
                </div>)
                }
            </div>
        </main>
    </div>
  );
};


export default Stage_5;