import React from 'react';
import { Input } from '../../../components';

import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import { toast } from 'react-toastify';
import { simuladorCalculo } from '../helpers';

const Power = (props) => {
    const[test , setTest] = [null, null];
    const {flow, states, setters} = props;
    const {setLoading, setSimuladorOCR} = setters;
    const {loading} = states;

    const handleInputChange = (index, key) => (value) => {
      setSimuladorOCR(prevState => ({
        ...prevState,
        potencia: prevState.potencia.map((item, i) =>
          i === index ? { ...item, [key]: value!=='' ? Number(value) : '' } : item
        ),
      }))
    };

    const handleFormSubmit = async (e) => {
       
      /*ACTIVANDO LOADING*/
       setLoading(true);
      try{
        let response = await simuladorCalculo(props);
        if (response.status === "success"){
          flow.nextStage()
        }
        console.log(response);

      }catch (error) {
        toast.error(error.message, {position: "top-center", autoClose: 3000})
      }
      finally{
        /*DESACTIVANDO LOADING*/
        setLoading(false);
      }
    }

    return (
      <>
        {loading && (
          <div className="loading">
            {/* BALL */}
            <div className="container-ball">
              <div className="loader_box">
                <div className='ball'></div>
                <div className='ball'></div>
                <div className='ball'></div>
              </div>
              <div className="loader_box">
                <div className='shadow'></div>
                <div className='shadow'></div>
                <div className='shadow'></div>
              </div>
            </div>
            {/* TEXT */}
            <span className="loading-text">PROCESANDO ...</span>
            
          </div>
         )}
        
        <div className='screen screen-2'>
          <div className="sidebar">
            {/*SIDE BAR */}
            <div className="sidebar__container">
                <div className="sidebar__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>
                <div className="sidebar__container--title">
                    <h2 className="h2-title">¡Más transparentes que la energía!</h2>
                </div>
                <div className="sidebar__container--subtitle">
                    <p className="subtitle">Entiende cada detalles de tu consumo y ahorra más que nunca.</p>
                </div>
            </div>

            <div className="sidebar__container--background"></div>
            </div>

            <main className="main">
              <div className="main__container">
                  {/* FORMULARIO */}
                  <div className="main__container--form">
                      <form className="form" id="stage_3_form">
                          <div className="form__row">
                              <h2>TERMINOS EN POTENCIA</h2>
                          </div>

                          {/* POTENCIA*/}
                          {(states?.simuladorOCR?.potencia?.length ? states.simuladorOCR.potencia : Array(6).fill({})).map((field, index) => (
                            <div className="form__row" key={index}>
                              <h2 style={{ display: "block" }}>P{index + 1}</h2>
                              {/* PRECIO */}
                              <Input type='number' name={`precio_p${index + 1}`}  placeholder='Precio (€/kWh)' required={false}
                                     value= {field.precio_potencia}
                                     update={handleInputChange(index, 'precio_potencia')}
                                     />
                              
                              {/* CONTRATADA */}
                              <Input type='number' name={`contratada_p${index + 1}`}  placeholder='Contratada' required={false} 
                                     value= {field.potencia_contratada}
                                     update={handleInputChange(index, 'potencia_contratada')}
                              />
                              
                              {/* DÍAS */}
                              <Input type='number' name={`dias_p${index + 1}`}  placeholder='Días' required={false}
                                     value= {field.dias_factura}
                                     update={handleInputChange(index, 'dias_factura')}
                              />
                              
                              {/* DESCUENTO */}
                              <Input type='number' name={`descuento_p${index + 1}`}  placeholder='Descuento (0 a 100)' required={false}
                                     value= {field.descuento}
                                     update={handleInputChange(index, 'descuento')}
                              />
                            </div>
                            ))
                          }

                      </form>

                  </div>

                  <div className="main__container--buttons">
                      <a onClick={() => flow.previousStage()}  className="button back"><i className="fa-regular fa-arrow-left"></i> Volver</a>
                      <a onClick={handleFormSubmit}  className="button continue">Adelante <i className="fa-regular fa-arrow-right"></i></a>
                  </div>

              </div>
            </main>
        </div>
      </>
    ) 
  }

export default Power;