import React, {useState} from 'react'
// import Stages from './Stages'
import { useNavigate, useSearchParams} from 'react-router-dom';
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom';

import './simulador.css'

import * as AllStages from './Stages'
import {createHubspotLead, createOfferCalculadora, advanceFlowCTA, contrataYaEnd} from './helpers'

const Stages = Object.values(AllStages);
const n_stages = Stages.length;
const last_stage = n_stages - 1;

const Simulador = ({debug}) => {
    
    const [searchParams]= useSearchParams();    
    const navigate = useNavigate();
    
    const [hsLead, setHsLead] = useState(false);

    // PODRIA SER UN DICCIONARIO CON TODO SEARCH PARAMS Y FORM
    const planta = searchParams.get("utm_planta") || "";
    const origen_1 = searchParams.get("utm_origen_1") || "";
    const origen_2 = searchParams.get("utm_origen_2") || "";
    const origen_3 = searchParams.get("utm_origen_3") || "";
    const origen_4 = searchParams.get("utm_origen_4") || "";
    
    const [tarifa, setTarifa] = useState('');
    
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    
    const [email, setEmail] = useState('');
    const [emailRequired, setEmailRequired] = useState(true);

    const [telefono, setTelefono] = useState('');
    const [countryCode, setCountryCode] = useState('ES');

    const [cp, setCp] = useState('');
    const [newsletter, setNewsletter] = useState(false);
    
    const [gasto, setGasto] = useState('');
    const [consumo, setConsumo] = useState('');
    const [factura, setFactura] = useState(null);

    const [facturaUploaded, setFacturaUploaded] = useState(false);
    const [iban, setIban] = useState('');
    const [dniAnverso, setDniAnverso] = useState(null);
    const [dniReverso, setDniReverso] = useState(null);

    const [contrataYaSent, setContrataYaSent] = useState(false);

    const [loading, setLoading] = useState(false);

    const [simuladorOCR, setSimuladorOCR] = useState(null)

    const [offerData, setOfferData] = useState(null);

    const[stage, setStage] = useState(0);
    
    const nextStage = (increment = 1) => {
        setStage((stage + increment) % n_stages);
    }
    const previousStage = (decrement = 1) => {
        setStage((stage - decrement) % n_stages);
    }

    const Contrata =  () => setStage(last_stage - 1);
    
    const Llamamos = () => setStage(last_stage);
    
    const states = {nombre, apellidos, email, emailRequired, telefono, countryCode, cp, newsletter, tarifa, gasto, consumo, factura, planta, origen_1, origen_2, origen_3, origen_4, hsLead, stage, offerData, iban, dniAnverso, dniReverso, facturaUploaded, contrataYaSent, loading, simuladorOCR};
    const setters = {setNombre, setApellidos, setEmail, setEmailRequired, setTelefono, setCountryCode, setCp, setNewsletter, setTarifa, setGasto, setConsumo, setFactura, setHsLead, setOfferData, setIban, setDniAnverso, setDniReverso, setFacturaUploaded, setContrataYaSent, setLoading, setSimuladorOCR};
    const props = {flow: {nextStage, previousStage, createHubspotLead, createOfferCalculadora, navigate, Contrata, Llamamos, advanceFlowCTA, contrataYaEnd}, states, setters};
        
    const Stage = Stages[stage];
    return(
    <>  
        <Stage {...props}/>
        {debug && 
        <div className='simulador_debug'>
            <button onClick={()=> previousStage()}>Regresar</button>
            <button onClick={()=> nextStage()}>Siguientes</button>
            <button onClick={Contrata}>Contrata Ya</button>
            <button onClick={Llamamos}>Te Llamamos</button>
        </div>
        }
    </>
    )
}

export default Simulador