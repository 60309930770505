import React from 'react'
import './debugbanner.css'


const DebugBanner = () => {
  return (
    <div className='debugBanner'>Debug</div>
  )
}

export default DebugBanner