import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import { Input } from '../../../components';
import React  from 'react';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

const Stage_2 = (props) => {
    const {flow, setters, states} = props;
    const handleFormSubmit = (e) => {
        const form_el = document.querySelector('#stage_2_form');
        e.preventDefault();
        
        const tel_input = document.querySelector('#tel_input');
        if (states.countryCode === "ES"){
            let string_tel = tel_input.value.replace(/ /g, '').replace('+34', '');
            const tel_pattern = /\b[0-9]{9}\b/;;
            if (!tel_pattern.test(string_tel)){
                tel_input.setCustomValidity('Teléfono no válido, debe tener 9 dígitos (sin prefijo)');
            }
            else{
                tel_input.setCustomValidity('');
            }
        }
        else{
            tel_input.setCustomValidity('');
        }

        if (!form_el.checkValidity()) {
            form_el.reportValidity();
        }
        else{
            setters.setNewsletter(form_el.querySelector('#newsletter').checked);
            flow.createHubspotLead(props);
            flow.nextStage()
        }
      }

    return (
        <div className="screen screen-2">
            <div className="sidebar">
                <div className="sidebar__container">
                    <div className="sidebar__container--logo">
                        <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                    </div>
                    <div className="sidebar__container--title">
                        <h2 className="h2-title">¡Hola, encantados de conocerte!</h2>
                    </div>
                    <div className="sidebar__container--subtitle">
                        <p className="subtitle">Queremos saber un poco más sobre ti para contarte todo lo que puedes ahorrar con nosotros.</p>
                    </div>
                </div>
                <div className="sidebar__container--background"></div>
                </div>

                <main className="main">
                    <div className="main__container">

                        <div className="main__container--steps">
                            {/* <span className="steps--number active">1</span>
                            <div className="steps--line"></div>
                            <span className="steps--number">2</span>
                            <div className="steps--line"></div>
                            <span className="steps--number">3</span> */}
                        </div>

                        <div className="main__container--form">
                            <form className="form" id="stage_2_form">
                                
                                {/* NOMBRE && APELLIDOS */}
                                <div className="form__row">
                                    <Input type='text' name='nombre' value={states.nombre} update={setters.setNombre} placeholder='Nombre *' required={true}/>
                                    <Input type='text' name='apellidos' value={states.apellidos} update={setters.setApellidos} placeholder='Apellidos *' required={true}/>
                                </div>
                                
                                {/* CORREO ELECTRONICO */}
                                <div className="form__row">
                                    <Input type='email' name='email' value={states.email} update={setters.setEmail} placeholder={`Email${states.emailRequired ? ' *' : ''}`} required={states.emailRequired}/>
                                </div>
                                
                                {/* TELEFONO & CODIGO POSTAL */}
                                <div className="form__row">
                                    <PhoneInput style={{width: '100%'}} id="tel_input" name='telefono' defaultCountry={states.countryCode} value={states.telefono} onChange={setters.setTelefono} placeholder='Teléfono *' required={true} onCountryChange={setters.setCountryCode}/>
                                    <Input props={{maxLength: "5", minLength: "5", pattern:"[0-9]{5}", title:"El código postal debe ser un número de 5 dígitos"}} type='text' name='cp' value={states.cp} update={setters.setCp} placeholder='Código postal *' required={true}/>
                                </div>

                                {/* TEXTO DE ACEPTACIÓN */}
                                <div className="form__row--checkboxes">
                                    <div className="form__row--checkbox">
                                        <Input type='checkbox' name='politica' value={undefined} update={_ => {}} required={true}/>
                                        <label htmlFor="politica">He leído y acepto la <a href="https://tuenergiamascercana.es/politica-de-privacidad/" target="_blank">política de privacidad</a>.</label>
                                    </div>

                                    <div className="form__row--checkbox">
                                        <Input type='checkbox' name='newsletter' value={undefined} update={_ => {}}/>
                                        <label htmlFor="newsletter">Acepto el envío de comunicaciones comerciales.</label>
                                    </div>
                                </div>
                            </form>
                    </div>

                    {/* BOTONES */}
                    <div className="main__container--buttons">
                        <a onClick={() => flow.previousStage()} className="button back"><i className="fa-regular fa-arrow-left"></i> Volver</a>
                        <a onClick={handleFormSubmit} className="button continue">Adelante <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </main>
        </div>
    ) 
  }
  

  export default Stage_2;