import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';

const Stage_6 = (props) => {
  return (
    <div className="screen screen-3">
        <main className="main">
        <div className="main__container">
                <div className="main__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>

                <div className="main__container--title">
                    <h2 className="h2-title">¡Gracias por dejarnos ayudarte a ahorrar en tu factura de electricidad!</h2>
                </div>

                <div className="main__container--subtitle">
                    <p className="subtitle">En menos de 24 horas nos pondremos en contacto contigo para darte toda la información que necesitas. Vas a comprobar lo transparentes que podemos ser a la hora de hablar de electricidad.</p>
                </div>
            </div>
        </main>
    </div>
  );
};


export default Stage_6;