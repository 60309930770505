import React from 'react';
import logo from '../../../assets/img/TEMC_ByBarter_Logo.svg';

const Stage_1 = (props) => {
    const {flow, setters} = props;
    return (
        
      <div className='screen screen-1' style={{overflow:"hidden"}}>
        <main className="main">
          <div className="main__container">
            <div className="main__container--logo">
                <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
            </div>
            <div className="main__container--title">
                <h1 className="h1-title">Comprueba todo lo que puedes ahorrar con energía solar en tu localidad.</h1>
            </div>
            <div className="main__container--buttons">
                <a onClick={() => {setters.setTarifa('2.0'); flow.nextStage();}} className="button continue">En tu casa <i className="fa-regular fa-arrow-right"></i></a>
                <a onClick={() => {setters.setTarifa('3.0'); flow.nextStage();}} className="button continue">En tu negocio <i className="fa-regular fa-arrow-right"></i></a>
            </div>
          </div>
        </main>
        <div className="screen-1--background"></div>
      </div>
    ) 
  }


export default Stage_1;