import React from 'react';
import { Input } from '../../../components';

import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import { toast } from 'react-toastify';
import { readFacture } from '../helpers';

const Stage_3 = (props) => {
    const[test , setTest] = [null, null];
    const {flow, states, setters} = props;
    const {setLoading} = setters;
    const {loading} = states;

    const handleFormSubmit = async (e) => {
        const form_el = document.querySelector('#stage_3_form');
        e.preventDefault();
    
        if (!form_el.checkValidity()) {
          form_el.reportValidity();
        }
        else{
          if (!states.gasto && !states.consumo  && !states.facturaUploaded){
            toast.warning('Debes introducir al menos un gasto, un consumo o subir una factura.', {position: "top-center", autoClose: 3000});
            // return;
          }else if (states.facturaUploaded){
            /*ACTIVANDO LOADING*/
            setLoading(true);

            /*ENVIO DE FACTURA PARA LECTURA*/
            try {
              if (states.facturaUploaded) {
                // ENVIO DE FACTURA PARA LECTURA
                await readFacture(props);
                flow.nextStage()
              }
            } catch (error) {
              toast.error(error.message, {position: "top-center", autoClose: 3000})
              
            } finally {
              setLoading(false);
            }
          }else {
            /*ACTIVANDO LOADING*/
            setLoading(true);

            /*CREAR OFERTA*/
            await flow.createOfferCalculadora(props);
          }
        }
      }

    return (
      <>
        {loading && (
          <div className="loading">
            {/* BALL */}
            <div className="container-ball">
              <div className="loader_box">
                <div className='ball'></div>
                <div className='ball'></div>
                <div className='ball'></div>
              </div>
              <div className="loader_box">
                <div className='shadow'></div>
                <div className='shadow'></div>
                <div className='shadow'></div>
              </div>
            </div>
            {/* TEXT */}
            <span className="loading-text">PROCESANDO ...</span>
            
          </div>
         )}
        
        <div className='screen screen-2'>
          <div className="sidebar">
            {/*SIDE BAR */}
            <div className="sidebar__container">
                <div className="sidebar__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>
                <div className="sidebar__container--title">
                    <h2 className="h2-title">¡Más transparentes que la energía!</h2>
                </div>
                <div className="sidebar__container--subtitle">
                    <p className="subtitle">Entiende cada detalles de tu consumo y ahorra más que nunca.</p>
                </div>
            </div>

            <div className="sidebar__container--background"></div>
            </div>

            <main className="main">
              <div className="main__container">
                  {/* STEP INDICATOR */}
                  <div className="main__container--steps">
                      {/* <span className="steps--number active">1</span>
                      <div className="steps--line"></div>
                      <span className="steps--number active">2</span>
                      <div className="steps--line"></div>
                      <span className="steps--number">3</span> */}
                  </div>

                  {/* FORMULARIO */}
                  <div className="main__container--form">
                      <form className="form" id="stage_3_form">
                          <div className="form__row">
                              <p className="text">Con dos datos que encuentras en tu factura de electricidad, te daremos el cálculo de ahorro que podríamos generarte. ¿Los recuerdas?</p>
                          </div>
                          {/* GASTO & CONSUMO */}
                          <div className="form__row">
                              <Input type='number' name='gasto' value={states.gasto} update={setters.setGasto} placeholder='Gasto promedio mensual' required={false}/>
                              <Input type='number' name='consumo' value={states.consumo} update={setters.setConsumo} placeholder='Consumo promedio mensual' required={false}/>
                          </div>

                          <div className="form__row">
                              <p className="text smaller">Adjúntanos tu última factura de electricidad y seremos todavía más precisos (opcional).</p>
                          </div>
                          {/* FILE SELECTOR */}
                          <div className="form__row">
                          {!states.factura
                          ? 
                          (
                            <div className="container-select-file">
                              <label htmlFor='factura' className="btn-select-file">
                                <i className="fa-regular fa-upload upload-icon"></i>
                                Cargar Factura
                              </label>
                              <Input type='file' name='factura' accept=".pdf" 
                              value={''} update={(e) => {setters.setFactura(e); setters.setFacturaUploaded(true)}}/>
                            </div>
                          )
                          : 
                          ( 
                            <a className='btn-select-file' onClick={() => {setters.setFactura(null); setters.setFacturaUploaded(false);}} >
                              <i className="fa-regular fa-trash-can delete-icon"></i>
                              Eliminar Factura
                            </a>
                          )}
                          </div>
                      </form>

                  </div>

                  <div className="main__container--buttons">
                      <a onClick={() => flow.previousStage()}  className="button back"><i className="fa-regular fa-arrow-left"></i> Volver</a>
                      <a onClick={handleFormSubmit}  className="button continue">Adelante <i className="fa-regular fa-arrow-right"></i></a>
                  </div>

              </div>
            </main>
        </div>
      </>
    ) 
  }

  export default Stage_3;
  