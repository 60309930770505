import axios from 'axios';
import { toast } from 'react-toastify';
import { getCountryCallingCode} from 'react-phone-number-input';


const padPhone = (countryCode, tel) => {
  const numericCountryCode = getCountryCallingCode(countryCode);
  return tel.replace(`${numericCountryCode}`, `${numericCountryCode.padStart(4, '0')}`);
}

export const createHubspotLead = async (props) =>{
    const {states} = props;
    const {hsLead} = states;
    const {setHsLead, setEmailRequired, setEmail} = props.setters;
    const {nextStage} = props.flow;
    if (hsLead){
      nextStage();
      return;
    }

    // states.telefono  = padPhone(states.countryCode, states.telefono);

    const loadingId = toast.loading('Cargando ...', {position: "top-right"});
    await axios.post('/api/HSLead', {states}).then(res => res.data)
      .then(
        data => {
          const {id} = data;
          setHsLead(id);
          nextStage();
        })
      .catch(
        err => {
          const data = err?.response?.data;

          if (data.managed){
            const {code} = data;
            if (code === 'EMAIL_VALIDATION_ERROR'){
              toast.error("Error en el sistema, intente de nuevo.", {position: "top-center"});
            }
            else if (code === 'EMAIL_EXISTS'){
              toast.error("Este email ya está registrado en nuestro sistema. Por favor, utilice otro email o deje el campo vacío.", {position: "top-center"});
              setEmailRequired(false);
              setEmail('');
            }
          }
          else{
            toast.error('Error al enviar los datos');
          }
        });
    toast.dismiss(loadingId);
}

export const advanceLeadHubspot = async (url, props) => {
    const {hsLead, factura} = props.states;

    if (!hsLead)
        return;
    
    const formData = new FormData();
    formData.append('id', hsLead);
    formData.append("url_oferta_comercializadora", url);
    formData.append('file', factura);

    await axios.patch('/api/HSLead', formData).then(res => res.data).then(data => data);
}

export const createOfferCalculadora  = async (props) => {
    const {states} = props;
    const {setOfferData, setLoading} = props.setters;
    const {navigate, previousStage, nextStage} = props.flow;

    await axios.post('/api/offer', states).then(response => response.data)
    .then(data => {
      advanceLeadHubspot(data?.url, props);
      setOfferData(data?.offerData);
      nextStage(4); //VISTA DE OFERTAS
    })
    .catch(error => {
      const managed_error = error?.response?.data
      const msg = managed_error || 'Error al enviar los datos';
      toast.error(msg);
      console.error("Error ", error);
      previousStage();
    }
    );
    /*OCULTANDO LOADING*/
    setLoading(false);
};


export const advanceFlowCTA = async (props, type, flowOption) => {

  const {hsLead} = props?.states;
  if (!hsLead){
    console.error("cannot update lead without id");
    return;
  }

  await axios.patch('/api/HSLead/CTA', {id: hsLead, type})
  .then(res => res.data)
  .then(_ => flowOption());

}

export const ibanValidity = (iban) => {

  return true;
  // Remove spaces and convert to uppercase
  iban = iban.replace(/ /g, '').toUpperCase();
    
  // Check if the IBAN is in the correct format
  if (!/^[A-Z]{2}\d{2}[A-Z\d]{4}\d{7}([A-Z\d]?){0,16}$/.test(iban)) {
      return false;
  }

  console.log('regex ok');

  // Move the first four characters to the end
  const rearrangedIBAN = iban.substr(4) + iban.substr(0, 4);
  
  // Replace letters with digits
  let numericIBAN = '';
  for (let i = 0; i < rearrangedIBAN.length; i++) {
      const char = rearrangedIBAN.charAt(i);
      numericIBAN += isNaN(char) ? char.charCodeAt(0) - 55 : char;
  }
  
  // Perform modulo operation
  const remainder = numericIBAN % 97;

  return remainder === 1;
}

export const contrataYaEnd = async (props) => {
  const {hsLead, factura, facturaUploaded, dniAnverso, dniReverso, iban} = props.states;

  // if (!hsLead)
  //     throw new Error("HsLead no encontrado");
  
  const formData = new FormData();
  formData.append('id', hsLead);
  formData.append('iban', iban);
  if (!facturaUploaded)
    formData.append('factura', factura);
  formData.append('dniAnverso', dniAnverso);
  formData.append('dniReverso', dniReverso);

  await axios.patch('/api/HSLead/CTA/contrata_ya', formData).then(res => res.data).then(data => data);
}

/*ENVIO DE FACTURA PARA LECTURA*/
export const readFacture = async(props) => {
  const {factura} = props.states;
  const { setSimuladorOCR } = props.setters
  
  // Define los tipos MIME permitidos
  const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
  
  if (factura) {
    // Captura el tipo MIME del archivo
    const { type } = factura;

    // Verifica si el tipo MIME está en la lista de permitidos
    if (allowedTypes.includes(type)) {
      
      const formData = new FormData();
      formData.append('factura', factura);
      
      try {
        const { data } = await axios.post('/api/ocr', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

       /*CAPTURAR INFORMACION*/
        const properties = loadDataFacture(data?.factura_ocr);
        /*ACTUALIZAR DATA PARA EL SIMULADOR */
        setSimuladorOCR(properties);
      
      } catch (error) {
        const message = error.response.data.message
        console.error(message);
        throw new Error(message);
      }
    
    } else {
      // Lanza un error específico si el tipo MIME no es permitido
        const message = `Tipo de archivo no soportado: ${type}`;
        throw new Error(message);
    }
  }
};

/*CARGAR DATOS DE LA FACTURA DE OCR*/
const loadDataFacture = (factureOCR) => {

  /*FECHA INICIO*/
  let fecha_inicio = null;
  if (factureOCR.informacionLuz.fechaInicio){
    fecha_inicio = {
      year: new Date(factureOCR.informacionLuz.fechaInicio).getFullYear(),
      month: new Date(factureOCR.informacionLuz.fechaInicio).getMonth() + 1,
      date: new Date(factureOCR.informacionLuz.fechaInicio).getDate(),
      formattedDate: factureOCR.informacionLuz.fechaInicio
    };
  }

  /*FECHA FIN*/
  let fecha_fin = null;
  if (factureOCR.informacionLuz.fechaFin){
    fecha_fin = {
      year: new Date(factureOCR?.informacionLuz?.fechaFin).getFullYear(),
      month: new Date(factureOCR?.informacionLuz?.fechaFin).getMonth() + 1,
      date: new Date(factureOCR?.informacionLuz?.fechaFin).getDate(),
      formattedDate: factureOCR?.informacionLuz?.fechaFin
    };
  }
  
  // Construcción del objeto properties con los datos extraídos de la factura
  const properties = {
      tarifa: factureOCR.informacionLuz.peaje??"",
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin,
      autoconsumo: 32,
      autoconsumo_switch: true,
      precio_autoconsumo: 0.1,
      dias_facturados: factureOCR.informacionLuz.diasFacturados??"",
      potencia: PotenciaInfo(factureOCR.informacionLuz.preciosPotencia, factureOCR.informacionLuz.diasFacturados),
      energia: EnergiaInfo(factureOCR?.informacionLuz?.preciosEnergia)
  };

  return properties
}

// Función para parsear la información de precios de potencia
const PotenciaInfo = (preciosPotencia, diasFacturados) => {
  if (!preciosPotencia) return [];
  
  let potencia = Array(6).fill({});
  preciosPotencia.forEach(elem => {
    /*INDEX: VALOR DE P.. PARA LUEGO ACTUALIZAR LA POSICION DEL ARREGLO*/
    let index =  Number(Array.from(elem.type)[1]);
    let data = {
      precio_potencia: elem.power_price,
      potencia_contratada: elem.power_supplied,
      dias_factura: diasFacturados,
      descuento: elem.discount, 
    }

    potencia[index - 1] = data
  })

  return potencia;
}

// Función para parsear la información de precios de potencia
const EnergiaInfo = (preciosEnergia) => {
  if (!preciosEnergia) return [];
  
  const energia = Array(6).fill({});

  // Usar forEach para recorrer el arreglo
  preciosEnergia.forEach(elem => {
    let index =  Number(Array.from(elem.time_discrimination_type)[1]);
    let data = {
      precio_energia: elem.usage_price,
      consumo: elem.energy_amount,
      descuento: elem.discount, 
    }

    energia[index - 1] = data
   
  });

  return energia
}


export const simuladorCalculo = async(props) => {
  const {simuladorOCR} = props.states;   
  const {setOfferData} = props.setters;

  let payload = {...simuladorOCR};
    
  payload.energia = simuladorOCR.energia.map((e, index)=> ({...e, periodo : index + 1}));
  payload.potencia = simuladorOCR.potencia.map((p, index)=> ({...p, periodo : index + 1}));

  payload.fecha_inicio = simuladorOCR.fecha_inicio.formattedDate;
  payload.fecha_fin = simuladorOCR.fecha_fin.formattedDate;

  try {
    const { data } = await axios.post('/api/simulador', payload);
    advanceLeadHubspot(data?.url, props);
    setOfferData(data?.offerData);
    return data;
  
  } catch (error) {
    const message = error.response.data.message
    console.error(message);
    throw new Error(message);
  }

}

    
    

    