import React from 'react'

const TablaOferta = ({offerData}) => {
  return (
    <div className="main__container--summary">
        <div className="summary__row">
            <p className="text">A partir de los datos que nos has facilitado (*), estas serán tus ventajas si contratas <strong>Tu energía más cercana</strong>. Ahorro asegurado con autoconsumo, si estas dentro del radio de 2000m de la planta o sin autoconsumo, si estás fuera.</p>
        </div>

        <div className="summary__row">
            <div className="summary__table">
                <div className="table">
                    <div className="table__row header">
                        <div className="table__right">
                            <div className="table__column">
                                <p className="text">Con Autoconsumo <br/>(-2000m)</p>
                            </div>

                            <div className="table__column">
                                <p className="text">Sin Autoconsumo <br/>(+2000m)</p>
                            </div>
                        </div>                                
                    </div>
                    <div className="table__row content">
                        <div className="table__left">
                            <div className="table__column">
                                <p className="text">Ahorro <br/>mensual estimado</p>
                            </div>
                        </div>
                        <div className="table__right">
                            <div className="table__column">
                                <p className="quantity">{offerData?.Ahorro_A} €</p>
                            </div>
                            <div className="table__column">
                                <p className="quantity">{offerData?.Ahorro_NoA} €</p>
                            </div>
                        </div>                                
                    </div>

                    <div className="table__row content">
                        <div className="table__left">
                            <div className="table__column">
                                <p className="text">Ahorro <br/>anual estimado</p>
                            </div>
                        </div>

                        <div className="table__right">
                            <div className="table__column">
                                <p className="quantity">{offerData?.AhorroAnual_A} €</p>
                            </div>

                            <div className="table__column">
                                <p className="quantity">{offerData?.AhorroAnual_NoA} €</p>
                            </div>
                        </div>                                
                    </div>

                    <div className="table__row content">
                        <div className="table__left">
                            <div className="table__column">
                                <p className="text">Porcentaje anual <br/>de ahorro estimado</p>
                            </div>
                        </div>

                        <div className="table__right">
                            <div className="table__column">
                                <p className="quantity">{offerData?.AhorroPerc_A}%</p>
                            </div>

                            <div className="table__column">
                                <p className="quantity">{offerData?.AhorroPerc_NoA}%</p>
                            </div>

                        </div>                                
                    </div>
                </div>
            </div>
        </div>

        <div className="summary__row">
            <p className="text">(*) Ahorro calculado en base a los precios vigentes de mercado según fecha de la factura aportada. El ahorro puede oscilar según variabilidad del mercado.</p>
            <p className="text">(**) Validez de la oferta: 15 días.</p>
        </div>
    </div>
  )
}

export default TablaOferta