import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Simulador, Oferta} from './containers'
import {Header, DebugBanner} from './components'

import './normalize.min.css'
import './outfit.css'
import './reset.css'
import './index.css'
import './app.css'
import './simuladorv2.css'
import './assets/fontawesome/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css';

const debug = process.env.REACT_APP_DEBUG;

if (debug) console.log('DEBUG MODE ON');

const App = () => {
    return (
    <>
        {debug && <DebugBanner/>}
        <Header/>
        <ToastContainer/>
        <Router>
            <Routes>
                <Route exact path="*" element={<Navigate to={'/'}/>}/>
                <Route path="/" element={<Simulador debug={debug}/>}></Route>
                <Route path="/oferta/:id" element={<Oferta/>}/>
            </Routes>
        </Router>
    </>
    )
}

export default App;