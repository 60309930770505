import logo from '../../../assets/img/TEMC_ByBarter_Logo_blanco.svg';
import distancia_image from '../../../assets/img/TEMC_distancia.svg';
import { TablaOferta } from '../../../components';

const Stage_4 = (props) => {
  const {states} = props;

  const {advanceFlowCTA, Contrata, Llamamos} = props.flow;

  const {offerData} = states;
  
  return (
    <div className="screen screen-2">
        <div className="sidebar">
            <div className="sidebar__container">
                <div className="sidebar__container--logo">
                    <img src={logo} alt="Tu energía más cercana by Barter" className="logo"/>
                </div>

                <div className="sidebar__container--title">
                    <h2 className="h2-title">¡Esto si es ahorro!</h2>
                </div>

                <div className="sidebar__container--subtitle">
                    <p className="subtitle">Es el momento de dar el siguiente paso con nosotros y pagar sólo por lo que consumes.</p>
                </div>

                <div className="sidebar__container--info-image">
                    <img src={distancia_image} alt="Más ahorro y menos ahorro"/>
                </div>
            </div>
        </div>

        <main className="main">
            <div className="main__container">
                <div className="main__container--steps">
                    {/* <span className="steps--number active">1</span>
                    <div className="steps--line"></div>
                    <span className="steps--number active">2</span>
                    <div className="steps--line"></div>
                    <span className="steps--number active">3</span> */}
                </div>

                <TablaOferta offerData={offerData}/>
                

                <div className="main__container--buttons" style={{marginTop: "1rem"}}>
                    <div className="buttons--right">
                        <a onClick={() => advanceFlowCTA(props, "contrata", Contrata)} className="button continue">Contrata ya <i className="fa-regular fa-arrow-right"></i></a>
                        <a onClick={() => advanceFlowCTA(props, "llama", Llamamos)} className="button continue">Te llamamos <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </main>
    </div>

    // <div className='stage_content_form'>
    //     <form id='stage_3'>
    //       <Input type='number' name='gasto' value={states.gasto} update={setters.setGasto} label='Gasto' placeholder='Eur' required={false}/>
    //       <Input type='number' name='consumo' value={states.consumo} update={setters.setConsumo} label='Consumo'  placeholder='kWh' required={false}/>
    //       {!states.factura
    //       ? (<Input type='file' name='factura' value={''} update={setters.setFactura} label='Factura (opcional)'/>)
    //       : (<button type="button" onClick={() => setters.setFactura(null)} >Recargar factura</button>)}
    //       <button type='button' onClick={handleFormSubmit}>Confirmar</button>
    //     </form>
    //   </div>
  ) 
}


export default Stage_4;